<template>
  <div class="app-main" id="main">
    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
        <div class="col-md-12 m-b-30">
          <!-- begin page title -->
          <div class="d-block d-sm-flex flex-nowrap align-items-center">
            <div class="page-title mb-2 mb-sm-0">
              <h1>Account Settings</h1>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <nav>
                <ol class="breadcrumb p-0 m-b-0">
                  <li class="breadcrumb-item">
                    <a>
                      <i class="ti ti-home"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">Pages</li>
                  <li
                    class="breadcrumb-item active text-primary"
                    aria-current="page"
                  >Account Settings</li>
                </ol>
              </nav>
            </div>
          </div>
          <!-- end page title -->
        </div>
      </div>
      <!-- end row -->

      <!--mail-Compose-contant-start-->
      <div class="row account-contant">
        <div class="col-12">
          <Loader v-if="$store.getters.isLoading" />
          <div v-else class="card card-statistics">
            <div class="card-body p-0">
              <div class="row no-gutters">
                <div class="col-xl-5 col-md-6 col-12 border-t border-right">
                  <div class="page-account-form">
                    <div class="form-titel border-bottom p-3">
                      <h5 class="mb-0 py-2">Edit System Settings</h5>
                    </div>
                    <div class="p-4">
                      <form>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label for="name1">Airtime Deduct(promo) %</label>
                            <input
                              type="text"
                              class="form-control"
                              id="airtime deduct"
                              @change="isNumber()"
                              v-model="appSettings.airtime_deduct"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="title1">Cable TV Deduct(promo) %</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title1"
                              @change="isNumber()"
                              v-model="appSettings.cabletv_deduct"
                            />
                          </div>
                          <div class="form-group col-md-12">
                            <label for="title1">Data Deduct(promo) %</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title1"
                              @change="isNumber()"
                              v-model="appSettings.data_deduct"
                            />
                          </div>
                           <div class="form-group col-md-12">
                            <label for="title1">Physician Fee</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title1"
                              @change="isNumber()"
                              v-model="appSettings.physician_chat_fee"
                            />
                          </div>
                             <div class="form-group col-md-12">
                            <label for="title1">Referal Bonus</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title1"
                              @change="isNumber()"
                              v-model="appSettings.referal_bonus"
                            />
                          </div>
                            <div class="form-group col-md-12">
                            <label for="title1">Transfer Service Fee</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title1"
                              @change="isNumber()"
                              v-model="appSettings.transfer_service_fee"
                            />
                          </div>
                          
                         
                        </div>

                        <button
                          @click.prevent="updateVendor()"
                          class="btn btn-primary"
                        >Update Settings</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--mail-Compose-contant-end-->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      appSettings: {
    
      }
    };
  },
  components: {
    
  },
  mounted() {
    this.getAppSettings();

  },
  methods: {
    getAppSettings() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "app_settings/get", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.appSettings = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
  
    updateVendor() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "app_settings/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.appSettings)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.appSettings = result.data;
           
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped>
</style>